<template>
  <div class="app-container summary-page trading-record">
    <eHeader
      :step-name="stepName"
      ref="eheader"
      @toQuery="toQueryHandle"
    ></eHeader>
    <el-table
        v-loading="loading"
        :data="data"
        stripe
        size="small"
        style="width: 100%;"
    >
      <el-table-column prop="id" label="ID"/>
      <el-table-column prop="orderId" label="客户信息">
        <template slot-scope="scope">
          <div>{{scope.row.custName}}</div>
          <div>{{scope.row.custId}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="login" label="兑换商品">
        <template slot-scope="scope">
          <span>{{scope.row.productName}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="login" label="商品类型">
        <template slot-scope="scope">
          <span>{{getProductType(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="quantity" label="数量" />
      <el-table-column prop="consumedPoint" label="抵扣积分" />
      <el-table-column prop="createTime" label="申请时间">
        <template slot-scope="scope">
          <span>{{scope.row.createTime}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="completeTime" label="完成时间">
        <template slot-scope="scope">
          <span>{{scope.row.updateTime}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="openPrice" label="状态">
        <template slot-scope="scope">
          <span>{{getStatus(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          width="150px"
      >
        <template slot-scope="scope">
          <el-button
              size="mini"
            type="success"
              @click="checkHandle(scope.row)"
          >查看</el-button>
          <el-button
              v-if="auditBtnShow"
              size="mini"
              type="danger"
              @click="auditHandle(scope.row)"
          >审核</el-button>
        </template>
      </el-table-column>
    </el-table>
    <exchange-detail
      :dialog-show="dialogShow"
      :step-name="stepName"
      @close="dialogShow = false"
      @audit-success="auditSuccess"
      :data="currentRow"
    ></exchange-detail>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
import exchangeDetail from '@/components/intergratingManage/exchangeRecord/exchangeDetail'
import checkPermission from '@/utils/permission'
import initDataComm from '@/mixins/initData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/intergratingManage/exchangeRecord/eheader'
import { initData } from '@/api/data'
export default {
  name:'auditList',
  components: {
    eHeader,
    exchangeDetail,
  },
  props: {
    stepName: {
      type: String,
      default: 'all'
    }
  },
  mixins: [initDataComm],
  data() {
    return {
      loading: false,
      dialogShow: false,
      currentRow:{},
      viewData: {}
    }
  },
  watch:{
    stepName(val){
      this.page = 0;
      this.init();
    },
  },
  computed: {
    viewBtnShow() {
      return true;
    },
    auditBtnShow() {
      return this.stepName == 'waitAudit' && checkPermission(['ADMIN','TCustRewardRedeemRecord_ALL','TCustRewardRedeemRecord_EDIT'
      ]);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      let header = this.$refs.eheader;
      this.url = 'crm/rewardRedeemRecord';
      this.params = {
        page: this.page,
        size: this.size,
      }
      if(this.stepName == 'all'){
        if(header.status){
          this.params.status = header.status;
        }
      }
      if(this.stepName == 'waitAudit'){
        this.params.status = 1;
      }
      if(this.stepName == 'finish'){
        this.params.status = 3;
      }
      if(this.stepName == 'reject'){
        this.params.status = 2;
      }
      if(header.productType){
        this.params.productType = header.productType;
      }
      if(header.basicInfo){
        this.params.custInfo = header.basicInfo;
      }
      if(header.productName){
        this.params.productName = header.productName;
      }

      if(header.startTime){
        this.params.startTime = parseTime(header.startTime);
      }
      if(header.endTime){
        this.params.endTime = parseTime(header.endTime);
      }
      return true
    },
    checkHandle(row){
      //查看
      this.currentRow = row;
      this.dialogShow = true;
    },
    auditHandle(row){
      //审核
      this.currentRow = row;
      this.dialogShow = true;
    },
    toQueryHandle(){
      this.page = 0;
      this.init();
    },
    getStatus(row){
      switch (row.status){
        case 1:
          return '待审核';
        case 2:
          return '审核拒绝';
        case 3:
          return '审核通过';
      }
    },
    auditSuccess(){
      // 刷新下当前列表
      this.init();
    },
    getProductType(row){
      if(row.productType == 1){
        return '实物';
      }else if(row.productType == 2){
        return '虚拟';
      }
    }
  }
}
</script>

<style lang="less">
  .trading-record {
  }
</style>
