<template>
<div class="head-container">
  <el-select
      v-model="productType"
      @change="toQuery"
      clearable
      placeholder="商品类型"
      class="filter-item"
      style="width: 130px"
  >
    <el-option
        v-for="item in productTypeOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
    />
  </el-select>
  <el-select
      v-if="stepName == 'all'"
      v-model="status"
      @change="toQuery"
      clearable
      placeholder="状态"
      class="filter-item"
      style="width: 130px"
  >
    <el-option
        v-for="item in statusOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
    />
  </el-select>

  <el-input
      v-model="basicInfo"
      clearable
      placeholder="客户姓名/ID"
      style="width: 200px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
  />
  <el-input
      v-model="productName"
      clearable
      placeholder="商品名称"
      style="width: 200px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
  />
  <div class="filter-item">
    兑换时间：
    <el-date-picker
        style="width: 150px;"
        v-model="startTime"
        type="date"
        placeholder="开始日期">
    </el-date-picker>
    至
    <el-date-picker
        style="width: 150px;"
        v-model="endTime"
        type="date"
        placeholder="结束日期">
    </el-date-picker>
  </div>
  <el-button
      class="filter-item"
      size="mini"
      type="primary"
      icon="el-icon-search"
      @click="toQuery"
  >搜索</el-button>
  <el-button
      class="filter-item"
      size="mini"
      type="primary"
      icon="el-icon-close"
      @click="clear"
  >清空</el-button>
</div>
</template>

<script>
export default {
  name: 'eheader',
  props: {
    stepName: {
      type: String,
      default: 'all'
    }
  },
  data() {
    return {
      productType: '',
      status: '',
      productTypeOptions:[
        { label: '实物商品', value: 1 },
        { label: '虚拟商品', value: 2 },
      ],
      statusOptions:[
        { label: '待审核', value: 1 },
        { label: '审核拒绝', value: 2 },
        { label: '审核通过', value: 3 },
      ],
      productName: '',
      basicInfo: '',
      startTime: '',
      endTime: '',
    }
  },
  methods: {
    toQuery() {
      this.$emit('toQuery');
    },
    clear(){
      this.status = '';
      this.product = '';
      this.basicInfo = '';
      this.startTime = '';
      this.endTime = '';

      this.toQuery();
    }
  }
}
</script>

<style scoped>

</style>
