<template>
  <el-dialog
      :append-to-body="true"
      v-if="dialogShow"
      :visible="dialogShow"
      :title="title"
      width="1000px"
      class="audit-log-detail"
      :before-close="cancel"
  >
    <el-tabs
        v-model="activeName"
        @tab-click="handleClick"
    >
      <el-tab-pane
          v-if="checkPermission(['ADMIN','CUST_INFO_ALL','CUST_INFO_BASIC'])"
          label="申请信息"
          name="one"
      >
        <el-row>
          <el-col class="apply-inof-col" :span="8">
            <span class="label">客户姓名:</span>
            <span>{{ data.custName ||' ' }}</span>
          </el-col>
          <el-col class="apply-inof-col" :span="8">
            <span class="label">兑换商品:</span>
            <span>{{ data.productName}}</span>
          </el-col>
          <el-col class="apply-inof-col" :span="8">
            <span class="label">数量:</span>
            <span>{{ data.quantity}}</span>
          </el-col>
          <el-col class="apply-inof-col" :span="8">
            <span class="label">备注:</span>
            <span>{{ data.remark || '-'}}</span>
          </el-col>
          <el-col class="apply-inof-col" :span="8">
            <span class="label">申请时间:</span>
            <span>{{ parseTime(data.createTime)}}</span>
          </el-col>
          <el-col class="apply-inof-col" :span="8">

            <span class="label">状态:</span>
            <span>{{getStatus()}}</span>
          </el-col>
          <el-col
              class="apply-inof-col"
              v-if="data.productType == 1"
              :span="8">
            <span class="label">收货人姓名:</span>
            <span>{{ addressInfo.name}}</span>
          </el-col>
          <el-col
              class="apply-inof-col"
              v-if="data.productType == 1"
              :span="8">
            <span class="label">联系电话:</span>
            <span>{{ addressInfo.phone }}</span>
          </el-col>
          <el-col
              class="apply-inof-col"
            v-if="data.productType == 1"
          >
            <span class="label">收货地址:</span>
            <span>{{ data.address}}</span>
          </el-col>
          <el-col
              class="apply-inof-col"
              v-if="data.productType == 2"
          >
            <span class="label">赠金账户:</span>
            <span>{{ data.remark}}</span>
          </el-col>
        </el-row>
        <div v-if="isAuditStep" class="dialog-footer">
          <el-form
              ref="form"
            :model="form"
              :rules="rules"
          >
            <el-form-item
              lable="审核结论"
              prop="auditResult"
            >
              <el-radio-group
                  class="label"
                  v-model="form.auditResult"
              >
                <el-radio :label="3">通过</el-radio>
                <el-radio :label="2">拒绝</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              v-if="form.auditResult == 2"
              label="拒绝原因"
              prop="rejectReason"
            >
              <el-input
                  style="width: 400px"
                  row="5"
                  class="textarea-el"
                  type="textarea"
                  placeholder="请输入拒绝原因"
                  v-model="form.rejectReason"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div v-if="isAuditStep" class="dialog-footer done-line">
          <el-button
              type="text"
              @click="cancel"
          >取消</el-button>
          <el-button
              :loading="loading"
              type="primary"
              @click="doCheck"
          >确定</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane
          v-if="checkPermission(['ADMIN','CUST_INFO_DETAIL','CUST_INFO_ALL'])"
          label="详细资料"
          name="two"
      >
        <customer-detail-info
          :cust-id="data.custId"
        ></customer-detail-info>
      </el-tab-pane>
      <el-tab-pane
          v-if="checkPermission(['ADMIN','CUST_INFO_AUDIT_LOG','CUST_INFO_ALL'])"
          label="审核记录"
          name="three"
      >
        <el-table :data="[data]" style="width: 100%">
          <el-table-column prop="auditBy" label="审核人员">
            <template slot-scope="scope">
              <span>{{scope.row.auditBy || '-'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="eventContent" label="审核意见">
            <template slot-scope="scope">
              <div>{{getAuditStatus(scope.row)}}<span v-if="scope.row.status == 2" class="reason">({{scope.row.rejectReason}})</span></div>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="操作时间" width="180">
            <template slot-scope="scope">
              <span>{{ parseTime(scope.row.createTime) }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
import { parseTime } from '@/utils/index'
import customerDetailInfo from '@/components/public/customerDetailInfo'
import { auditExchange } from '@/api/intergratingManage/productManage'
import checkPermission from '@/utils/permission'
import { initData } from '@/api/data'
export default {
  name: 'exchangeDetail',
  components:{
    customerDetailInfo
  },
  props: {
    stepName: {
      type: String,
      default: 'all',
    },
    dialogShow: {
      type: Boolean,
      default: false,
    },
    data:{
      type: Object,
      default(){
        return {
          userName:'',
        }
      }
    }
  },
  data() {
    return {
      activeName: 'one',
      events: null,
      form:{
        auditResult: 3,
        rejectReason: '',
      },
      rules:{
        auditResult: [
          {required: true,message: '请选择审核结论',trigger: 'change'}
        ],
        rejectReason: [
          {required: true,message: '请选择审核结论',trigger: 'blur'}
        ],
      }
    }
  },
  watch:{
    dialogShow(val){

    },
  },
  computed: {
    title() {
      return this.stepName != 'waitAudit' ? '查看': '审核';
    },
    isAuditStep(){
      return this.stepName == 'waitAudit';
    },
    addressInfo(){
      return JSON.parse(this.data.addressSnapshot);
    },
  },
  methods: {
    parseTime,
    checkPermission,
    close(){
      this.form = {
        auditResult: 3,
        rejectReason: '',
      }
      this.$emit('close');
    },
    cancel() {
      this.close();
    },
    btnCancel(){
      // 按钮取消
    },
    handleClick(tab, event) {

    },
    getStatus(){
      switch (this.data.status){
        case 1:
          return '待审核';
        case 2:
          return '审核拒绝';
        case 3:
          return '已完成';
      }
    },
    getAuditStatus(row){
      switch (row.status){
        case 1:
          return '待审核';
        case 2:
          return '审核拒绝';
        case 3:
          return '审核通过';
      }
    },
    doCheck(){
      this.$refs.form.validate((valid) => {
        if(valid){
          let params = {
            id: this.data.id,
            status: this.form.auditResult,
          };
          if(this.form.auditResult == 2){
            params.rejectReason = this.form.rejectReason;
          }
          auditExchange(params).then((res) => {
            this.$notify({
              title: '操作成功',
              type: 'success',
              duration: 2500
            });
            this.close();
            this.$emit('audit-success');
          })
        }else {

        }
      })
    },
    getEvents(){
      var url = 'crm/tCustEvent'
      var params = {
        custId: this.data.id,
        sort: 'createTime,desc',
      }
      initData(url, params)
          .then(res => {
            this.events = res.content
          })
          .catch(err => {
            console.log(err.response.data.message)
          })
    }
  }
}
</script>

<style lang="less" scoped>
.audit-log-detail {
  .apply-inof-col {
    margin-bottom: 20px;
    padding: 10px 0;
  }
  .label {
    padding: 10px 10px;
  }
  .reject-text,
  .label {
    font-size: 18px;
  }
  .done-line {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
